body {
  margin: 0;
  color: #444;
  font-family: "Montserrat", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

input [type="text"], [type="password"], [type="email"], [type="number"], [type="tel"], textarea {
  background-color: white;
  border: 2px solid #DFE1DF;
  border-radius: 0;
  padding: 0.8rem 1.2rem;

  width: 20rem;
  max-width: 100%;
}