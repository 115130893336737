.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.input {
margin: 0.8rem 1.2rem;
  font-size: 18pt;



}
